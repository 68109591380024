.Upload {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
}

.Content {
    /*display: flex;*/
    flex-direction: row;
    padding-top: 16px;
    box-sizing: border-box;
    width: 100%;
}

.Files {
    align-items: flex-start;
    justify-items: flex-start;
    flex: 1;
    overflow-y: auto;
    width: 100%;
}

.Actions {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 32px;
}

.Title {
    margin-bottom: 32px;
    color: #555;
}
.Filename {
    color: #555;
}
/*button {*/
    /*font-family: 'Roboto medium', sans-serif;*/
    /*font-size: 14px;*/
    /*display: inline-block;*/
    /*height: 36px;*/
    /*min-width: 88px;*/
    /*padding: 6px 16px;*/
    /*line-height: 1.42857143;*/
    /*text-align: center;*/
    /*white-space: nowrap;*/
    /*vertical-align: middle;*/
    /*-ms-touch-action: manipulation;*/
    /*touch-action: manipulation;*/
    /*cursor: pointer;*/
    /*-webkit-user-select: none;*/
    /*-moz-user-select: none;*/
    /*-ms-user-select: none;*/
    /*user-select: none;*/
    /*border: 0;*/
    /*border-radius: 2px;*/
    /*background: rgba(103, 58, 183, 1);*/
    /*color: #fff;*/
    /*outline: 0;*/
/*}*/

/*button:disabled {*/
    /*background: rgb(189, 189, 189);*/
    /*cursor: default;*/
/*}*/

.ProgressBar {
    width: 100%;
    height: 3px;
    background-color: #091859;
    border-radius: 2px;
    position: relative;
}

.Progress {
    background-color: #33BE97;
    height: 100%;
    margin: 0;
    border-radius: 2px;
}
.ProgressValue {
    position: absolute;
    transform: translateX(-100%);
    top: -18px;
    font-size: 12px;
    transition: all 0.3s;
}

.CheckIcon {
    opacity: 0.5;
    margin-left: 6px;
    height: 16px;
}

.ProgressWrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}

.Row {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 32px;
    overflow: hidden;
    box-sizing: border-box;
}

.Dropzone {
    height: 100%;
    width: 100%;
    min-height: 74px;
    padding: 10px;
    background-color: #fff;
    border: 2px dashed rgb(228 226 226);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
}

.Dropzone.Error {
    border-color: #DB2D0E;
}

.Icon {
}
.FileInput {
    display: none;
}

.Highlight {
    background-color: #aeffe8;
}