.aq-date-wrapper {
  font-size: 14px;
  position: relative;
  text-align: left;
}

.aq-date-wrapper .aq-date-tab {
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.aq-date-wrapper .aq-date-tab.selected {
  border-bottom: 1px solid #33be97;
}

.aq-date-wrapper input {
  border: 0;
  padding: 10px;
  outline: none;
}

.aq-date-wrapper .react-datepicker-wrapper {
  flex: 1;
  text-align: center;
  position: absolute;
  top: 40px;
  opacity: 0;
}
.aq-date-wrapper .react-datepicker__tab-loop {
}
.aq-date-wrapper .react-datepicker {
  font-family: "Rubik";
  border: 1px solid #dedede;
  margin-top: -21px;
  border-radius: 5px;
  position: absolute;
  z-index: 11;
  left: 0;
  box-shadow: 1px 1px 8px 0 #dcdcdc;
}
.aq-date-wrapper .react-datepicker .react-datepicker__triangle {
  font-family: "Rubik";
  border-bottom-color: #fff;
  display: none;
}

.aq-date-wrapper {
  margin-top: 1px;
}
.aq-date-wrapper .react-datepicker__header {
  background-color: white;
  border: none;
  color: #747474;
}
.aq-date-wrapper .react-datepicker__day {
  color: #747474;
}
.aq-date-wrapper .react-datepicker__header .react-datepicker__current-month {
  color: #333;
}
.aq-date-wrapper .react-datepicker__header .react-datepicker__day-name {
  color: #747474;
}
.aq-date-wrapper .react-datepicker__header .react-datepicker__day-names {
  background-color: white;
}
.aq-date-wrapper
  .react-datepicker__header
  .react-datepicker__day-names
  .react-datepicker__day-name {
  transform: translateY(6px);
  width: 43px;
  line-height: 16px;
  margin: 0;
}

.showTimer .react-datepicker__tab-loop .react-datepicker-popper {
  width: 406px;
}
.aq-date-wrapper .react-datepicker__month {
  background-color: white;
}
.aq-date-wrapper .react-datepicker__month .react-datepicker__week {
}
.aq-date-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day {
  border: 1px solid #dedede;
  margin: -1px 0 0 -1px;
  border-radius: 0;
  width: 42px;
  height: 42px;
  line-height: 44px;
}

.aq-date-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--selected,
.aq-date-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-range,
.aq-date-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--keyboard-selected,
.aq-date-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-selecting-range,
.aq-date-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-selecting-range:hover {
  background-color: #33be97;
  color: #fff;
}

.react-datepicker__year-dropdown {
  border-color: #dedede;
  box-shadow: 1px 1px 8px 0 #dcdcdc;
}
.react-datepicker__navigation--years {
  display: inline;
  top: -12px;
}
.react-datepicker__navigation--years-previous {
  top: 12px;
}
.react-datepicker__year-option {
  background-color: white;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__year-option:last-of-type {
  background-color: #f0f0f0;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  background-color: #eaeaea;
  color: grey;
  cursor: not-allowed;
}

.react-datepicker__day--disabled:hover {
  background-color: #eaeaea;
}

.react-datepicker__time-list-item {
  height: auto !important;
}
.react-datepicker__time-list-item.text-error {
  cursor: none;
  color: #dedede;
}

.react-datepicker__time-list-item.text-success {
  color: #33be97;
}
li.react-datepicker__time-list-item.text-success.react-datepicker__time-list-item--selected {
  background-color: #33be97 !important;
}

@media screen and (max-width: 677px) {
  .aq-date-wrapper
    .react-datepicker__month
    .react-datepicker__week
    .react-datepicker__day,
  .aq-date-wrapper
    .react-datepicker__header
    .react-datepicker__day-names
    .react-datepicker__day-name {
    width: 30px;
  }
}
