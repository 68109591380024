body {
  height: 100vh;
  width: 100vw;
}

#root {
  font-size: 16px;
  line-height: 19px;
  font-family: "Rubik";
  height: 100vh;
  background-color: #2d3766;
}

.medium {
  font-family: "Rubik-Medium";
}

.bold {
  font-family: "Rubik-Bold";
}

.light {
  font-family: "Rubik-Light";
}

.right {
  float: right;
}

.txt-center {
  text-align: center;
}

[class^="icon-"],
[class^="aq-icon-"] {
  cursor: pointer;
}

.icon-pdf:before {
  content: url("../img/pdf.svg");
}

.icon-edit:before {
  content: url("../img/edit.svg");
}

.icon-trash:before {
  content: url("../img/trash.svg");
}
.icon-download:before {
  content: url("../img/download.svg");
}

.error-txt:before {
  background-image: url("../img/error-circle.svg");
  background-repeat: no-repeat;
  padding-right: 16px;
  content: " ";
}

.h3-title {
  color: #2d3766;
  font-size: 24px;
  margin-bottom: 44px;
}

.error-txt {
  color: #db2d0e;
  font-size: 12px;
}

.global-loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(1, 1, 1, 0.45);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
}

.warning-box {
  padding: 0 10px;
  border: 1px solid #fa6968;
  font-size: 12px;
  border-radius: 6px;
  background-color: #fdf1f1;
  display: flex;
  align-items: center;
  height: 41px;
}

.warning-box:before {
  content: url("../img/exclamation-circle-orange.svg");
  margin-right: 5px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.aq-color-txt,
a,
.link-txt {
  color: #33be97;
}

a {
  cursor: pointer;
}

.grid {
  width: 100%;
}

.span-12 {
  width: 100%;
}

.span-11 {
  width: 91.66%;
}

.span-10 {
  width: 83.33%;
}

.span-9 {
  width: 75%;
}

.span-8 {
  width: 66.66%;
}

.span-7 {
  width: 58.33%;
}

.span-6 {
  width: 50%;
  padding-right: 20px;
}

.span-5 {
  width: 41.66%;
}

.span-4 {
  width: 33.33%;
}

.span-3 {
  width: 25%;
}

.span-2 {
  width: 16.66%;
}

.span-1 {
  width: 8.33%;
}

[class*="span-"] {
  display: inline-block;
  box-sizing: border-box;
}

.no-padding {
  padding: 0;
}

.aq-error-panel {
  border: 1px solid #db2d0e;
  background-color: #db2d0e;
  color: #ffffff;
  padding: 16px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  animation: slideIn 0.2s;
  z-index: 5;
}

@-webkit-keyframes slideIn {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0px);
  }
}

label {
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  font-family: "Rubik-Medium";
}

.big-label {
  font-size: 18px;
}

a:hover {
  text-decoration: underline;
}

.aq-txt-l {
  text-align: left;
}

.aq-error-txt {
  color: #db2d0e;
}

.flex {
  display: flex;
}

.flexible {
  flex: 1;
}

.flex-justify-center {
  justify-content: center;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-wrap {
  flex-wrap: wrap;
}

.slide-in-anim {
  animation: slide-in 0.3s ease-in-out;
}

@keyframes slide-in {
  0% {
    transform: translateY(-30px) scaleY(0.9);
    opacity: 0;
  }
  100% {
    transform: translateY(0) scaleY(1);
    opacity: 1;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  padding-bottom: 24px;
}

h3 {
  font-size: 18px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 677px) {
  .h3-title {
    color: #33be97;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .span-6 {
    width: 100%;
    padding: 0;
  }
}
